import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';
import scrollTo from '../../utils/scrollTo';
import useOpacityScroll from '../../utils/useOpacityScroll';

import './index.scss';

export default function Intro({ className, title, subtitle, article, showButton }) {
  const introRef = useRef(null);
  const opacity = useOpacityScroll(introRef);

  const classes = classNames('Intro', 'rellax', className);

  return (
    <section className={classes} ref={introRef}>
      <div className="Intro__container">
        <div className="grid">
          <div className="Intro__header" style={{ opacity }}>
            <div>
              <h1 className="Intro__title" itemProp={article ? 'headline' : null}>
                {title}
              </h1>
            </div>
            {subtitle ? (
              <p className="Intro__text" itemProp={article ? 'datePublished' : null}>{subtitle}</p>
            ) : null}
            {showButton ? (
              <div className="Intro__more">
                <Button onClick={() => scrollTo('#categories')}>
                  See now
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

Intro.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  article: PropTypes.bool,
  showButton: PropTypes.bool,
};

Intro.defaultProps = {
  className: '',
  subtitle: '',
  article: false,
  showButton: true,
};
