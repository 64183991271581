import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export default function Button({ onClick, children }) {
  return (
    <span
      className="Button"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <span>
        {children}
      </span>
    </span>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
};

Button.defaultProps = {
  onClick: () => {},
};
